/* You can add global styles to this file, and also import other style files */

/* Style Angular Material Legacy Components */
@use '@angular/material' as mat;
@use 'bootstrap/scss/bootstrap' as *;
@use "@ng-select/ng-select/themes/default.theme.css" as *;
@use "flatpickr/dist/flatpickr.css" as *;
@use  "mapbox-gl/dist/mapbox-gl.css" as *;
@use "@mapbox/mapbox-gl-geocoder/lib/mapbox-gl-geocoder.css" as *;

@include mat.elevation-classes();
@include mat.app-background();
@include mat.elevation-classes();
@include mat.app-background();

$my-primary: mat.m2-define-palette(mat.$m2-indigo-palette, 500);
$my-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

$my-theme: mat.m2-define-light-theme((color: (primary: $my-primary,
        accent: $my-accent,
      ),
      typography: mat.m2-define-typography-config(),
      density: 0,
    ));

@include mat.all-component-themes($my-theme);
@include mat.all-component-themes($my-theme);

:root {
  --bs-nav-link-font-size: 14px;
}

// ngx-datatable: Master Table class
// fixed-header: The header is fixed on the table
// datatable-header: Header row class
// datatable-header-cell: Header cell class
// resizeable: Cell resizeable class
// sortable: Cell drag/drop sortable class
// longpress: Cell long-press activated
// dragging: Cell dragging activated
// sort-active: Sort active on column
// sort-asc: Sort active on column with ascending applied
// sort-desc: Sort active on column with descending applied
// datatable-header-cell-label: Header cell text label
// draggable: Header cell draggable class
// datatable-body-row: Body row class
// datatable-row-even: Odd row class
// datatable-row-odd: Even row class
// datatable-body-cell: Body cell class
// sort-active: Sort active on column
// sort-asc: Sort active on column with ascending applied
// sort-desc: Sort active on column with descending applied

// $gray-200: #f2f2f2;

.datatable-header {
  border-bottom: 1px solid $gray-200;
  font-size: 12px;
  text-transform: uppercase;
  background-color: $gray-200;
  position: sticky;
  top: 0;
  z-index: 40;
}

.datatable-header-cell {
  padding: 0 8px;
}

.datatable-body-row {
  border-bottom: 1px solid $gray-200;
}

.datatable-body-cell-label {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 8px;
  font-size: 12px;
}

.datatable-body-row.active {
  background-color: #434343;
  color: white;
}

.ngx-datatable {
  overflow-y: auto !important;
}

// @import "_utilities";

body {
  background-color: rgb(240, 240, 240);
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

#planning_assistant_container mapboxgl-ctrl-geocoder {
  height: 28px;
}

#planning_assistant_container .mapboxgl-ctrl-geocoder--icon {
  height: 18px;
  top: 5px;
}

#planning_assistant_container .mapboxgl-ctrl-geocoder--input {
  height: 28px;
}

#planning_assistant_container .mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--pin-right>* {
  top: 0
}

#planning_assistant_container .mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--pin-right>* svg {
  margin-top: 5px;
}

#planning_assistant_container .mapboxgl-ctrl-geocoder--input {
  font-size: .75rem;
}

#planning_assistant_container .mat-grid-tile.normal_alignment>.mat-grid-tile-content {
  align-items: normal !important;
}

#planning_assistant_container .mapboxgl-popup-content {
  opacity: 1;
  background-color: #333;
  color: white;
  text-align: center;
}

#planning_assistant_container mat-form-field.no_bottom_padding .mat-mdc-form-field-wrapper {
  margin-bottom: -1.25em;
}

#planning_assistant_container .mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
#planning_assistant_container .mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
#planning_assistant_container .mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: #333;
}
#planning_assistant_container .mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
#planning_assistant_container .mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
#planning_assistant_container .mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: #333;
}
#planning_assistant_container .mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: #333;
}
#planning_assistant_container .mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: #333;
}

ul.breadcrumb {
  padding: 10px 16px;
  list-style: none;
  margin: 0;
}

ul.breadcrumb li {
  display: inline;
  font-size: 18px;
}

ul.breadcrumb li+li:before {
  padding: 8px;
  color: black;
  content: "/\00a0";
}

ul.breadcrumb li a {
  color: #337ab7;
  text-decoration: none;
}

ul.breadcrumb li a:hover {
  color: #01447e;
  text-decoration: underline;
}

/* Custom Styles Start */
.quicksettings {
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 0 0.5rem 0;
  margin-bottom: 0 !important;
}
.t-header {
  padding: 0.5rem 0 0.5rem 0.3rem;
}
.quicksettings button {
  margin: 5px
}
.g-link {
  color: #337ab7;
  text-decoration: none;
}
.g-actions {
  display: flex;
  padding: 0.5rem 0;
}
.btn.btn-primary {
  background-color: #337ab7;
  font-weight: 300;
  &:hover {
    background-color: #3376b1;
  }
}
.b-custom {
  li {
    padding: 0.5rem 0.5rem;
    &:not(:last-child)::after {
      content: '/';
      margin-left: 0.5rem;
    }
  }
}

/* Custom styles for ag-grid --- Start
*/

.bold {
  font-weight: bold;
}
.label-success {
  background-color: #5cb85c !important;
}
.label-warning {
  background-color: #f0ad4e !important;
}
.labe-default {
  background-color: #777;
}
.label-danger {
  background-color: #d9534f !important;
}
.label-own {
  background-color: #777;
  color: #ffffff;
  display: inline;
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
}

/* Custom styles for ag-grid --- END
*/

/* Custom Styles End */